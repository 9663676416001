<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title class="d-flex justify-space-between flex-wrap">
          Signup Report
          <div class="event-filters d-flex justify-space-between">
            <v-select
              v-model="filter"
              class="filter-input"
              :items="filterTypes"
              label="Type"
              item-text="label"
              item-value="value"
            ></v-select>
            <v-dialog ref="dialog" v-model="dialog" width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="filter-input"
                  v-model="dateRangeText"
                  label="Date range"
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="dates = []"
                  clearable
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker v-model="dates" range scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.dialog.save(dates)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-btn class="ma-2" dark color="primary" @click="paginate">
              Filter
            </v-btn>
          </div>
        </v-card-title>
        <v-expansion-panels multiple v-if="!loading && reports.length">
          <v-expansion-panel v-for="report in reports" :key="report.lookup_id">
            <v-expansion-panel-header expand-icon="mdi-menu-down">
              <div class="pa-0">
                <p class="mb-0">
                  <span
                    v-if="filter === 'course'"
                    class="tag"
                    :class="{ legacy: !report.is_scorm }"
                  >
                    {{ report.is_scorm ? "Scorm" : "Legacy" }}
                  </span>
                  <span class="font-weight-bold"
                    >({{ report.id }}) - {{ report.title }}</span
                  >
                </p>
                <div class="row">
                  <div class="col-lg-3 col-sm-12">
                    <h4 class="font-weight-light mb-0">
                      Total Signup: {{ report.total_sign_up }}
                    </h4>
                  </div>
                  <div class="col-lg-3 col-sm-12">
                    <h4 class="font-weight-light mb-0">
                      Total Unique: {{ report.total_unique }}
                    </h4>
                  </div>
                  <div v-if="filter === 'course'" class="col-lg-3 col-sm-12">
                    <h4 class="font-weight-light mb-0">
                      Total Certificate: {{ report.total_certs }} ({{
                        report.total_certs_percentage
                      }}%)
                    </h4>
                  </div>
                </div>
                <v-btn
                  color="primary"
                  class="my-2"
                  @click.stop="downloadCSVData(report)"
                >
                  Download CSV
                </v-btn>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                class="table-one"
                :headers="headers"
                :items="report.students"
                :items-per-page="10"
              >
                <template v-slot:item="{ item }">
                  <template v-if="filter === 'course'">
                    <tr
                      :class="
                        getMultipleSignUp(item.name, report.repeating_students)
                          ? 'multiple-signup'
                          : ''
                      "
                    >
                      <td>{{ getValue(item, "created_at", "") | datetime }}</td>
                      <td>{{ getValue(item, "customer.description", "") }}</td>
                      <td>{{ getValue(item, "name", "") }}</td>
                      <td>{{ getValue(item, "email", "") }}</td>
                      <td>
                        <div
                          v-if="
                            report.lookup_id ===
                              item.certificate_info.course_lookup_id &&
                            item.certificate_info.certificate_received_date
                          "
                        >
                          {{
                            getValue(
                              item,
                              "certificate_info.certificate_received_date",
                              ""
                            ) | datetime
                          }}
                        </div>
                      </td>
                      <td>
                        {{
                          report.lookup_id ===
                          item.certificate_info.course_lookup_id
                            ? getValue(item, "certificate_info.take", "")
                            : 0
                        }}
                      </td>
                      <td>
                        <div
                          v-if="
                            getMultipleSignUp(
                              item.name,
                              report.repeating_students
                            )
                          "
                        >
                          {{
                            getMultipleSignUp(
                              item.name,
                              report.repeating_students
                            )
                          }}x
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="filter === 'customer'">
                    <tr
                      :class="
                        getMultipleSignUp(item.name, report.repeating_students)
                          ? 'multiple-signup'
                          : ''
                      "
                    >
                      <td>{{ getValue(item, "created_at", "") | datetime }}</td>
                      <td>{{ getValue(item, "name", "") }}</td>
                      <td>{{ getValue(item, "email", "") }}</td>
                      <td>{{ getValue(item, "courses_count", "") }}</td>
                      <td>{{ getValue(item, "courses_ids", "") }}</td>
                      <td>
                        {{ getValue(item, "courses_certificate_received", "") }}
                      </td>
                      <td>{{ getValue(item, "courses_takes", "") }}</td>
                    </tr>
                  </template>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div
          v-if="!loading && !reports.length"
          class="d-flex justify-center flex-wrap subtitle-1 pa-15"
        >
          No signup report for this date range.
        </div>
        <div v-if="loading" class="d-flex justify-center flex-wrap pa-15">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import { getSignUpReport } from "@/services/signUpService";
import _ from "lodash";
import moment from "moment";

export default {
  metaInfo: {
    title: "SignUp Report",
  },
  data() {
    return {
      dates: [],
      filter: "course",
      filterTypes: [
        {
          label: "Course",
          value: "course",
        },
        {
          label: "Customer",
          value: "customer",
        },
      ],
      loading: false,
      dialog: false,
      reports: [],
    };
  },
  mounted() {
    const localStorageValue = localStorage.getItem("lms-sign-up-dates");
    this.dates =
      !localStorageValue || !JSON.parse(localStorageValue).length
        ? [
            moment().subtract(1, "months").format("YYYY-MM-DD"),
            moment().format("YYYY-MM-DD"),
          ]
        : JSON.parse(localStorageValue);

    this.paginate();
  },
  methods: {
    async paginate() {
      this.loading = true;
      localStorage.setItem(
        "lms-sign-up-dates",
        JSON.stringify(this.sortedDates)
      );
      const { data } = await getSignUpReport({
        startDate: this.sortedDates[0],
        endDate: this.sortedDates[1],
        filter: this.filter,
      });

      this.reports = data;
      this.loading = false;
    },

    downloadCSVData(report) {
      let csv = `${this.jsonFields}\n`;
      const data = _.get(report, "students");

      data.forEach((row) => {
        if (this.filter === "course") {
          const array = [
            moment(row.created_at).format("YYYY-MM-DD"),
            _.get(row, "customer.description", ""),
            _.get(row, "name", ""),
            _.get(row, "email", ""),
            _.get(row, "certificate_info.certificate_received_date", ""),
            _.get(row, "certificate_info.take", ""),
          ];
          csv += _.join(array, ";");
          csv += "\n";
        } else {
          const array = [
            moment(row.created_at).format("YYYY-MM-DD"),
            _.get(row, "name", ""),
            _.get(row, "email", ""),
            _.get(row, "courses_count", ""),
            _.replace(_.get(row, "courses_ids", ""), /\s/g, ""),
            _.get(row, "courses_certificate_received", ""),
            _.get(row, "courses_takes", ""),
          ];
          csv += _.join(
            _.map(array, (value) => `"${value}"`),
            ";"
          );
          csv += "\n";
        }
      });

      csv +=
        this.filter === "course"
          ? `Total;${report.total_unique}\n`
          : `"Total";"${report.total_unique}"\n`;

      const bom = "\uFEFF"; // UTF-8 BOM
      const anchor = document.createElement("a");
      anchor.href =
        "data:text/csv;charset=utf-8," + encodeURIComponent(bom + csv);
      anchor.target = "_blank";
      anchor.download = `${this.getFileName(report)}.csv`;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    },
  },
  computed: {
    headers() {
      return this.filter === "course"
        ? [
            {
              text: "Create Date",
              align: "start",
              sortable: false,
              value: "created_at",
            },
            { text: "Customer", value: "customer.description" },
            { text: "Name", value: "name" },
            { text: "Email", value: "email" },
            { text: "Certificate", value: "certificate" },
            { text: "Takes", value: "takes" },
            { text: "Multiple Signup", value: "multiple_sign_up" },
          ]
        : [
            {
              text: "Create Date",
              align: "start",
              sortable: false,
              value: "created_at",
            },
            { text: "Student Name", value: "name" },
            { text: "Student Email", value: "email" },
            { text: "Courses", value: "courses_count" },
            { text: "Course IDs", value: "courses_ids" },
            { text: "Certificates", value: "courses_certificate_received" },
            { text: "Takes", value: "courses_takes" },
          ];
    },
    jsonFields() {
      return this.filter === "course"
        ? "createdate;customer;name;email;cert;takes"
        : '"createdate";"name";"email";"courses";"courseids";"certificates";"takes"';
    },
    dateRangeText: {
      get() {
        return this.dates ? this.dates.join(" ~ ") : "";
      },
      set(value) {
        return value;
      },
    },
    sortedDates() {
      const dates =
        _.size(this.dates) === 1
          ? [...this.dates, _.get(this.dates, "0")]
          : this.dates;

      return dates.slice().sort((a, b) => new Date(a) - new Date(b));
    },
    getFileName() {
      return ({ id, title }) =>
        `${this.sortedDates[0]}-${this.sortedDates[1]}-${this.filter}${id}-${title} report`;
    },
    getValue() {
      return (value, path, defaultValue = "") =>
        _.get(value, path, defaultValue);
    },

    getMultipleSignUp() {
      return (name, repeatingStudents) => {
        return _.get(repeatingStudents, name.toLowerCase(), 0);
      };
    },
  },
  filters: {
    datetime(value) {
      return moment(value).format("YYYY-MM-DD HH:mm");
    },
  },
  watch: {
    filter() {
      this.paginate();
    },
  },
};
</script>
<style lang="scss" scoped>
.event-filters {
  .filter-input {
    margin: 0 0.5rem;
  }

  ::v-deep .v-text-field__details {
    display: none;
  }
}

.tag {
  padding: 0 3px;
  margin-right: 0.5rem;
  border: 1px solid;
  border-radius: 0.25rem;

  &.legacy {
    border-color: black;
    background: #464646;
    color: white;
  }
}

::v-deep .table-one {
  tbody {
    tr {
      &:hover {
        background-color: #f2f3f8 !important;
      }
    }
  }

  .multiple-signup {
    background-color: rgb(255, 221, 117, 0.4);
  }
}
</style>
